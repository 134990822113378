
// All of these should eventually be removed from here, and dependencied added to wherever they are being used...
// Need to check what each of these do, as they might set some window variable that's in use today
// we might want to add shortcuts for these?

require("jquery-mousewheel"); // v3.13 - required by turnjs/zoom, apparently by fixedheadertable as well?

// behaviours that add functionality to a page depending on some element class should be required here.
require("./behavior/textarea");

// modules that instead leverage functionality to other scripts (like ajax) should be required by those scripts
require("/modules/accordion");
require("/modules/address");
require("/modules/alert");
require("/modules/basket");
require("/modules/breadcrumbs");
require("/modules/bundles");
require("/modules/checkboxlist");
require("/modules/confirm");
require("/modules/dialogs");
require("/modules/disabled");
require("/modules/dropdown");
require("/modules/expandablelist");
require("/modules/favorites");
import "/modules/fixed-header-table";
require("/modules/flipbook");
require("/modules/foldout");
require("/modules/handlebars");
require("/modules/imagegrid");
require("/modules/lightbox");
require("/modules/list");
require("/features/shared/listbuilder/listbuilder");
require("/modules/listfilter");
require("/modules/loader");
require("/modules/masthead");
require("/modules/media-player");
require("/modules/module");
require("/modules/multilistbox");
require("/modules/no-scroll");
require("/modules/order");
require("/modules/panel");
require("/modules/preview");
require("/modules/price");
require("/modules/productsearch");
require("/modules/pulldownmenu");
require("/modules/qtip");
require("/modules/reveal-modal");
require("/modules/scroll-locked");
require("/modules/search");
require("/modules/select2");
require("/modules/select-more");
require("/modules/tables");
require("/modules/tabs");
require("/modules/tiles");
require("/modules/theme");
require("/modules/toast");
require("/modules/upload");
require("/modules/validate");
