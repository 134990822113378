import $ from 'jquery';
import { isVisible } from "/js/utility/is-visible";
import Delay from '/js/utility/delay';

const resizeDelay = new Delay(50);

const mastHead = document.querySelector("header");
const footer = document.querySelector("footer");

let footerObserver;

/**
 * Initialize scroll locking on the elements
 * @param {HTMLElement|JQuery|string} elem whatever a jQuery constructor can take
 */
export function scrollLock(elem) {
	$(elem).each(function (i, elem) {
		if (isVisible(elem)) {
			initialize(elem);
		}
		else {
			whenVisible(elem, function () {
				initialize(elem);
			});
		}
	});
}

function whenVisible(elem, callback) {
	const observer = new IntersectionObserver(
		function (entries, o) {
			if (isVisible(elem)) {
				observer.disconnect();
				callback();
			}
		},
		{
			root: null,
			rootMargin: "0px",
			threshold: buildThresholdList(100),
		}
	);
	observer.observe(elem);
}

function initialize(elem) {
	const $sentinel = $('<div class="scroll-locked-sentinel"></div>');
	const $elem = $(elem);

	$elem.before($sentinel);

	if ($elem.parents(".reveal-modal").length && (window.CSS && CSS.supports && CSS.supports("position", "sticky"))) {
		initializeSticky($elem, $sentinel);
	} else {
		initializeFixed($elem, $sentinel);
	}

	$elem.addClass("scrollable");
}

function initializeSticky($elem, $sentinel) {
	// In this case we want to use the sentinel and the modal height to set the max-height of the scrollable
	const sentinel = $sentinel.get(0);
	const top = 200; // just use a standard height

	$sentinel.css({
		height: top + "px",
		marginTop: -top + "px",
	});

	//resizeScrollLockModal($elem, sentinel);
	createResizeObserver($elem.closest(".reveal-modal").get(0), $elem, resizeScrollLockModal);
	createSentinelObserver(sentinel, $elem, resizeScrollLockModal);
}

function resizeScrollLockModal($elem) {
	const height = $elem.closest(".reveal-modal").height();
	const top = $elem.get(0).getBoundingClientRect().top - 40; // double paddings

	$elem.css("maxHeight", height - top);
	$elem.addClass("scrollable");
}

function initializeFixed($elem, $sentinel) {
	const sentinel = $sentinel.get(0);
	const rect = sentinel.getBoundingClientRect();
	const top = rect.top + window.scrollY;

	$sentinel.css({
		height: top + "px",
		marginTop: -top + "px",
	});

	$elem.css("width", rect.width + "px");

	ensureFooterObserver();
	createResizeObserver(sentinel, $elem, setScrollLockedWidth);
	createSentinelObserver(sentinel, $elem, adjustScrollLockedTop);
}

// Resize handlers

function createResizeObserver(sentinel, elem, callback) {
	const resizeObserver = new ResizeObserver(
		(entries) => resizeDelay.then(() => $.each(entries, (i, entry) => callback(elem, entry)))
	);
	resizeObserver.observe(sentinel);
}

// Intersection / scroll handlers

function createSentinelObserver(sentinel, elem, callback) {
	const sentinelObserver = new IntersectionObserver(
		(entries, observer) => $.each(entries, (i, entry) => callback(elem, entry)),
		{
			root: null,
			rootMargin: "0px",
			threshold: buildThresholdList(sentinel.getBoundingClientRect().height)
		}
	);
	sentinelObserver.observe(sentinel);
}

function ensureFooterObserver() {
	if (!footer || footerObserver)
		return;

	footerObserver = new IntersectionObserver(
		function (entries, observer) {
			$.each(entries, function (i, entry) {
				adjustScrollLockedHeight(entry.intersectionRect.height);
			});
		},
		{
			root: null,
			rootMargin: "0px",
			threshold: buildThresholdList(footer.clientHeight),
		}
	);
	footerObserver.observe(footer);
}

function buildThresholdList(numSteps) {
	const thresholds = [];

	for (let i = 1.0; i <= numSteps; i++) {
		let ratio = i / numSteps;
		thresholds.push(ratio);
	}

	thresholds.push(0);
	return thresholds;
}

function setScrollLockedWidth(elem, entry) {
	const width = (entry.borderBoxSize) ?
		(entry.borderBoxSize[0]
			? entry.borderBoxSize[0].inlineSize // Chrome/Edge uses an array here
			: entry.borderBoxSize.inlineSize) // FF has a single item
		: entry.contentRect.width; // Compatibility fallback
	$(entry.target).next().css("width", width + "px");
}

function adjustScrollLockedHeight(height) {
	const locked = $(".scroll-locked.scrollable").not(".reveal-modal .scroll-locked.scrollable"); // this is only relevant for those that are not in a modal
	locked.css("bottom", height + "px");
}

function adjustScrollLockedTop(elem, entry) {
	const top = Math.max(entry.intersectionRect.height - 20, mastHead.getBoundingClientRect().height); // defaultPadding
	elem.css("top", top + "px");
}

// initialize on load

scrollLock($(".scroll-locked"));